import React, { useContext } from "react";
import { Button } from "grommet";
import styled from "styled-components";
import { Link } from "gatsby";
import UserContext from "../contexts/UserContext";
import PageWrapper from "../components/PageWrapper";
import FeatureFlagContext from "../contexts/FeatureFlagContext";
import DarkPageHeading from "../components/DarkPageHeading";

const ButtonArea = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
`;

const ScenarioButtonWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function Home() {
  const { currentUser } = useContext(UserContext);
  const { flags } = useContext(FeatureFlagContext);

  return (
    <PageWrapper>
      <DarkPageHeading>
        {currentUser && "Main Menu"}
        {!currentUser && "Would you like to play a game?"}
      </DarkPageHeading>
      {flags["test-flag"] && "Test Flag: true"}
      <ButtonArea>
        <ScenarioButtonWrapper>
          <Link to="/join">
            <Button size="large" label="Join a Quiz" data-cy="join-quiz" />
          </Link>
        </ScenarioButtonWrapper>
        {flags["events"] && (
          <ScenarioButtonWrapper>
            <Link to="/join-event">
              <Button size="large" label="Join an Event" data-cy="join-event" />
            </Link>
          </ScenarioButtonWrapper>
        )}
        {currentUser && (
          <>
            {/*<ScenarioButtonWrapper>*/}
            {/*  <Link to="/host">*/}
            {/*    <Button size="large" label="Hosting a Quiz" />*/}
            {/*  </Link>*/}
            {/*</ScenarioButtonWrapper>*/}
            <ScenarioButtonWrapper>
              <Link to="/quiz-list">
                <Button size="large" label="My Quizzes" data-cy="my-quizzes" />
              </Link>
            </ScenarioButtonWrapper>
            {flags["events"] && (
              <ScenarioButtonWrapper>
                <Link to="/event-list">
                  <Button size="large" label="My Events" data-cy="my-events" />
                </Link>
              </ScenarioButtonWrapper>
            )}
            <ScenarioButtonWrapper>
              <Link to="/question-database">
                <Button
                  size="large"
                  label="Question Database"
                  data-cy="question-database"
                />
              </Link>
            </ScenarioButtonWrapper>
          </>
        )}
      </ButtonArea>
    </PageWrapper>
  );
}
